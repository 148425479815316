.bridge {
  width: 100%;
  padding: 300px 0;
  background-image: url("../../../assets/img/bridge.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center center;

  @media all and (max-width: 1000px) {
    padding: 160px 0;
    background-size: 150%;
  }

  @media all and (max-width: 500px) {
    padding: 100px 0;
    background-size: 200%;
  }

  .content-wrapper {
    margin: 0 auto;
    max-width: 360px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      margin: 20px auto 15px;

      strong:after {
        content: "Infrastructure";
      }
    }

    > p {
      margin: 0;
      color: var(--grey-03);
      font-weight: 500;
      text-align: center;
    }
  }
}
