.mail-notifications {
  margin-bottom: 50px;

  .content {
    display: flex;
    justify-content: center;
  }

  .mail-notifications-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  h3 {
    font-size: 20px;
    font-weight: 700;
    margin: 20px 0 17px;

    @media all and (max-width: 544px) {
      font-size: 14px;
    }
  }

  .input-wrapper {
    width: 430px;
    background: var(--light);
    padding: 6px;
    border-radius: 10px;
    position: relative;
    display: flex;

    form {
      width: 100%;
      display: flex;
    }

    input {
      display: flex;
      flex-grow: 1;
      border: none;
      outline: unset;
      padding: 13px 220px 13px 13px;
      font-size: 14px;
      color: var(--grey-01);
      background: var(--light);
      margin: 0;
      width: calc(100% - 233px);

      &:placeholder {
        color: var(--grey-02);
      }
    }

    button {
      position: absolute;
      right: 6px;
      top: 6px;
    }

    @media all and (max-width: 544px) {
      width: calc(100% - 30px);
      margin: 0 0 90px;

      input {
        padding: 9px 144px 9px 9px;
      }

      button {
        padding: 12px 24px;
      }
    }

    @media all and (max-width: 360px) {
      width: calc(100% - 30px);
      margin: 0 0 90px;

      input {
        padding: 7px 115px 7px 7px;
      }

      button {
        padding: 10px 16px;
      }
    }
  }
}

.section-reset {
  overflow: visible;

  @media all and (max-width: 680px) {
    overflow: hidden;
  }
}
