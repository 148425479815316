.download-links-row {
  margin-bottom: 55px;
  text-align: center;

  a.primary {
    margin-right: 20px;
    display: inline-flex;
    align-items: center;
  }

  a.primary:last-child {
    margin-right: 0;
  }
}

// tablet
@media all and (max-width: 1023px) {
  .download-links-row {
    display: flex;
    flex-direction: column;
    align-items: center;

    a.primary {
      margin-right: 0;
      margin-bottom: 20px;
    }

    a.primary:last-child {
      margin-bottom: 0;
    }
  }
}