.expandable-icon {
  position: relative;
  width: 56px;
  height: 56px;
  display: flex;

  .vertical,
  .horizontal {
    background: var(--blue);
  }

  .vertical {
    position: absolute;
    left: 27px;
    top: 20px;
    width: 2px;
    height: 16px;
    transform-origin: 50% 50%;
    transition: all 0.9s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  .horizontal {
    position: relative;
    top: 27px;
    left: 20px;
    width: 16px;
    height: 2px;
  }

  &.expanded .vertical,
  &:hover .vertical {
    transform: rotate(90deg);
  }
}
