.title {
  display: flex;
  flex-direction: column;
  align-items: center;

  .block {
    padding: 2px 25px;
    background: var(--dark-blue-02);
    border-radius: 20px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 3;
    transition: all 0.25s ease-out;

    &:hover {
      background: var(--grey-01);
    }

    svg {
      width: 104px;
      height: auto;
    }

    span {
      font-size: 12px;
      line-height: 11px;
      font-weight: 500;
      text-transform: uppercase;
      margin-right: 15px;
      color: var(--grey-03);
    }
  }

  > p {
    font-size: 18px;
    font-weight: 500;
    margin: 0 auto 60px;
    color: var(--grey-03);
    text-align: center;

    @media all and (max-width: 768px) {
      margin: 0 15px 50px;
      font-size: 16px;
    }
  }

  h1 {
    @media all and (max-width: 768px) {
      margin-left: 15px;
      margin-right: 15px;
      line-height: 42px;
    }
  }

  .badge {
    position: relative;
    z-index: 3;
  }

  .hero-image {
    position: relative;
    z-index: 2;
    margin-top: -20px;
    margin-bottom: 75px;
    box-shadow: 0 0 80px var(--image-shadow);
    border-radius: 30px;
    border: 5px solid var(--grey-01);
    max-width: 1050px;

    @media all and (max-width: 1100px) {
      width: calc(100% - 40px);
      max-width: unset;
    }

    // tablet
    @media all and (max-width: 1024px) {
      margin-bottom: 40px;
    }
  }

  .title-socials {
    text-align: center;
    margin-bottom: 130px;

    p {
      margin: 0;
      margin-bottom: 10px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
    }

    a {
      margin-right: 15px;
    }

    a:last-child {
      margin-right: 0;
    }
  }
}

