.FAQ {
  margin: 300px auto;

  @media all and (max-width: 1024px) {
    margin: 150px auto;
  }

  @media all and (max-width: 680px) {
    margin: 80px auto;
  }

  .content-wrapper {
    max-width: 625px;
    margin: 0 auto;

    .accordion-wrapper {
      min-height: 625px;
    }

    @media all and (max-width: 665px) {
      max-width: unset;
      width: calc(100% - 40px);
      margin: 0 20px;
    }

    h2 {
      margin-bottom: 104px;

      strong:after {
        content: "Questions";
      }

      @media all and (max-width: 680px) {
        margin: 60px auto;
      }
    }
  }
}
