footer {
  margin: 0px auto 80px;
  padding-bottom: 80px;

  @media all and (max-width: 500px) {
    margin: 40px auto 40px;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .nav {
    max-width: 840px;
    padding: 50px 0 38px;
    border-top: 1px solid var(--grey-01);
    display: flex;
    justify-content: center;

    li {
      margin-right: 40px;
      font-size: 14px;
      font-weight: 500;

      a {
        transition: color 0.2s ease-out;

        &:hover {
          color: var(--grey-03);
        }
      }

      &:last-of-type {
        margin-right: 0;
      }
    }

    @media all and (max-width: 840px) {
      max-width: unset;
      width: 100%;
      flex-direction: column;
      padding: 40px 0 10px;

      li {
        margin: 0 0 20px;
        text-align: center;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  p {
    font-size: 12px;
    color: var(--grey-02);
    margin: 0;

    a {
      transition: all 0.2s ease-out;

      &:hover {
        color: var(--light);
      }
    }
  }
}
