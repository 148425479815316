.subtitle {
  padding: 3px 16px;
  border-radius: 20px;
  display: flex;

  p {
    margin: 0 0 0 7px;
    line-height: 24px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    color: var(--light);
  }

  .emoji {
    font-size: 16px;
    line-height: 24px;
  }

  &.accent {
    background-color: var(--blue);
  }

  &.subtle {
    background-color: var(--grey-01);
  }

  &.purple {
    background-color: var(--purple);
  }

  &.light {
    background-color: var(--light-transparent);
  }

  &.big {
    padding: 9px 25px;
    border-radius: 21px;

    @media all and (max-width: 768px) {
      padding: 5px 16px;
      border-radius: 20px;
    }

    p {
      margin: 0 0 0 12px;
    }
  }
}
