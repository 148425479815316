.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 100;
  overflow: auto;

  &__inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 60px 30px;
    min-height: 100%;
  }

  &__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: var(--dark-blue-01);
    opacity: 0.2;
    z-index: 0;
  }

  &.enter &__overlay {
    opacity: 0
  }

  &.exit &__overlay {
    opacity: 0.2;
  }

  &.enter-active &__overlay {
    opacity: 0.2;
    transition: all 0.2s;
  }
  &.exit-active &__overlay {
    opacity: 0;
    transition: all 0.2s 0.2s;
  }

  &__close-button {
    right: 10px;
    top: 10px;
    position: absolute;
    padding: 10px;
    display: inline-flex;
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: var(--light);
    mix-blend-mode: difference;
    z-index: 1;
  }

  &__dialog {
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 540px;
    width: 100%;;
    height: 100%;
    min-height: 625px;
    border-radius: 40px;
    border: 3px solid var(--grey-01);
    background-color: var(--dark-blue-01);
    box-shadow: 3px 4px 30px 20px var(--modal-shadow);
    overflow: hidden;
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  &.enter &__dialog {
    transform: translate3d(0, -50px, 0);
    opacity: 0,
  }

  &.exit &__dialog {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  &.enter-active &__dialog {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: all 0.2s 0.2s ease-out;
  }

  &.exit-active &__dialog {
    opacity: 0;
    transform: translate3d(0, -50px, 0);
    transition: all 0.2s ease-in;
  }
}