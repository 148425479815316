.installation-modal {
  &__image-container {
    position: relative;
    height: 275px;
    z-index: 0;
  }

  &__image {
    height: 100%;
    width: 100%;
    position: absolute;
    object-fit: cover;
    left: 0;
    top: 0;


    &.right-enter,
    &.left-enter {
      // opacity: 0;
    }
    
    &.right-enter {
      transform: translate3d(100%, 0, 0);
    }

    &.left-enter {
      transform: translate3d(-100%, 0, 0);
    }

    &.left-enter-active,
    &.right-enter-active {
      transform: translate3d(0, 0, 0);
      opacity: 1;
      z-index: 1;
      transition: all 0.4s ease-out;
    }
  }

  &__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 30px;
  }

  &__header {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
  }

  &__learn-more-link {
    font-size: 14px;
    color: var(--blue);
    display: inline-block;
    text-transform: uppercase;
    font-weight: 500;
    white-space: nowrap;

    svg {
      margin-left: 5px;
      margin-bottom: -2px;
    }
  }

  &__body {
    flex: 1 1 100%;
    color: var(--grey-02);

    h4 {
      color: var(--light);
      font-size: 25px;
      margin: 0 0 10px 0;
    }

    ul {
      list-style: disc;
      padding-left: 25px;
      margin: 10px 0;
    }

    b {
      font-weight: 600;
      margin: 10px 0;
    }

    &.right-enter {
      transform: translate3d(50px, 0, 0);
      opacity: 0;
    }
  
    &.right-exit {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }

    &.right-enter-active {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
    &.right-exit-active {
      opacity: 0;
      transform: translate3d(-50px, 0, 0);
    }

    &.left-enter {
      transform: translate3d(-50px, 0, 0);
      opacity: 0;
    }

    &.left-exit {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }

    &.left-enter-active {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }

    &.left-exit-active {
      opacity: 0;
      transform: translate3d(50px, 0, 0);
    }
  
    &.right-enter-active,
    &.right-exit-active,
    &.left-enter-active,
    &.left-exit-active {
      transition: all 0.2s;
    }
  }

  &__footer {
    display: flex;
    margin-top: 20px;
  }

  &__pagination {
    padding: 0;
    margin: 0;
    list-style: none;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__back-btn {
    transition: all 0.15s;

    &.is-hidden {
      opacity: 0;
      visibility: hidden;
    }
  }

  &__pagination-item {
    position: relative;
    padding: 3.5px;

    &:before,
    &:after {
      content: "";
      width: 7px;
      height: 7px;
      border-radius: 100%;
    }

    &:before {
      background-color: var(--grey-01);
    }

    &:after {
      position: absolute;
      background-color: var(--blue);
      opacity: 0;
      transition: opacity 0.1s;
    }

    &:hover:after {
      opacity: 0.3;
    }

    &.is-active:after {
      opacity: 1;
    }
  }


  @media (max-width: 450px) {
    &__footer {
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &__pagination {
      width: 100%;
      order: 1;
      margin-top: 20px
    }
  }
}
