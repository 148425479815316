.btn {
  position: relative;
  display: inline-flex;
  cursor: pointer;
  border: none;
  overflow: hidden;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  font-family: inherit; 
  font-weight: 600;
  background-color: transparent;
  // variants

  &--variant-solid:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--light);
    opacity: 0;
    transition: opacity 0.1s;
  }

  &--variant-solid:disabled,
  &--variant-solid:hover:before {
    opacity: 0.1;
  }
  &--variant-solid:active:before {
    opacity: 0.2;
  }

  &--variant-solid:disabled {
    background-color: var(--grey-01);
    color: var(--grey01);
  }

  &--color-light {

  }

  // colors
  &--color-blue {
    background-color: var(--blue);
    color: var(--light);
  }

  &--color-grey {
    background-color: var(--grey-01);
    color: var(--light);
  }



  &--size-md {

  }

  &--size-xs {
    height: 34px;
    padding: 5px 10px;
    font-size: 14px;
    min-width: 105px;
  }

  &--size-xs#{&}--variant-solid {
    border-radius: 10px
  }
}