.upcoming-features {
  background-image: url("../../../assets/img/mountain.png");
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: 100%;
  padding-bottom: 320px;
  padding-top: 260px;

  @media all and (max-width: 1100px) {
    padding-bottom: 200px;
    padding-top: 140px;
  }

  @media all and (max-width: 900px) {
    padding-top: 60px;
    padding-bottom: 160px;
  }

  @media all and (max-width: 500px) {
    padding-bottom: 60px;
  }

  ul {
    min-width: 1200px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media all and (max-width: 1200px) {
      min-width: unset;
    }

    @media all and (max-width: 900px) {
      flex-direction: column;
      align-items: center;
    }
  }

  li {
    width: 28%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 318px;

    @media all and (max-width: 900px) {
      width: 50%;
      align-items: center;
      margin-bottom: 60px;
    }

    @media all and (max-width: 560px) {
      width: 80%;
    }

    .img-wrapper {
      display: flex;
      margin-bottom: 30px;
      align-items: flex-start;

      img {
        margin: -30px;
      }

      .subtitle {
        margin-top: 12px;
        margin-left: -30px;
      }
    }

    .details-wrapper {
      display: flex;
      flex-direction: column;

      h4 {
        font-size: 25px;
        margin: 0 0 10px;
      }

      > p {
        font-size: 16px;
        font-weight: 500;
        color: var(--grey-03);
      }
    }
  }
}
