.bottom-downloads {
  position: relative;
  display: flex;
  justify-content: center;

  @media all and (max-width: 1024px) {
    flex-direction: column-reverse;
    align-items: center;
  }

  .visuals {
    display: inline-block;
    width: 880px;
    height: 560px;

    @media all and (max-width: 1024px) {
      width: 720px;
    }
  }

  .app-screenshot {
    position: absolute;
    width: 560px;
    top: 0;
    z-index: 3;
    box-shadow: 0 0 80px var(--image-shadow);
    border-radius: 30px;
    border: 5px solid var(--grey-01);
    background: var(--grey-01);
  }

    // tablet
    @media all and (max-width: 1023px){
      .visuals {
        width: 100%;
        height: auto;
      }
  
      .app-screenshot{
        width:60%;
        max-width: 560px;
      }
    }

     // mobile
     @media all and (max-width: 639px){
      .visuals {
        width: 100%;
        height: auto;
      }
  
      .app-screenshot{
        width:70%;
        max-width: 560px;
      }
    }
} 

.section-reset {
  overflow: visible;

  @media all and (max-width: 680px) {
    overflow: hidden;
  }
}
