.panel {
  background: var(--grey-01);
  border: 1px solid var(--grey-03);
  border-radius: 10px;
  padding: 30px 80px;
  width: 620px;
  margin: 0 auto;
  box-sizing: border-box;

  .head {
    font-size: 18px;
    line-height: 26px;
    margin-top: .5rem
  }

  .list {
    margin-left: 25px;

    li {
      list-style-type: disc;
      font-size: 14px;
      line-height: 20px;
      padding: 7px 0;
    }
  }

  @media all and (max-width: 620px) {
    width: 100%;
    padding: 30px 40px;
  }
}
