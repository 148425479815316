.badge {
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 130px;
  height: 30px;
  padding: 5px 15px;
  border-radius: 15px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;

  &--color-blue {
    color: var(--light);
    background-color: var(--blue);
  }
}