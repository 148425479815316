.accordion-item {
  display: flex;

  > div:first-of-type {
    flex-shrink: 0;
    margin-top: 15px;
  }

  border-top: 1px solid var(--grey-01);

  &:last-of-type {
    border-bottom: 1px solid var(--grey-01);
  }

  .item-wrapper {
    flex-direction: column;
    justify-content: center;
    display: flex;
    cursor: pointer;
    width: calc(100% - 56px);
    flex-basis: calc(100% - 56px);

    h5 {
      font-size: 18px;
      transition: all 0.2s ease-out;
      padding-left: 0;

      &:hover {
        padding-left: 6px;
      }
    }

    p {
      font-size: 16px;
      color: var(--grey-03);
      margin: 5px 0 30px;
      display: none;

      &.expanded {
        display: block;
      }
    }
  }
}
