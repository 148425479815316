.social-wrapper {
  max-width: 325px;
  margin: 300px auto 110px;

  @media all and (max-width: 640px) {
    margin-top: 200px;
  }

  h5 {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    margin: 0 0 20px;
  }

  .input-wrapper {
    position: relative;
    background: var(--dark-blue-02);
    border-radius: 5px;
    padding: 13px 15px;
    cursor: pointer;

    p {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: var(--grey-03);
    }

    button {
      position: absolute;
      top: 7px;
      right: 7px;
    }
  }

  ul {
    display: flex;
    margin-top: 40px;
    width: 100%;
    justify-content: center;

    li {
      margin: 0 6px;
    }
  }
}
