h1,
h2,
h3 {
  font-size: 60px;
  font-weight: 700;
  margin: 54px 0 10px;
  text-align: center;

  @media all and (max-width: 768px) {
    font-size: 30px;
  }

  strong {
    background: var(--gradient);
    color: var(--neon-pink);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    white-space: nowrap;

    &:after {
      content: "the Metaverse";
      position: absolute;
      left: 0;
      top: 0;
      background: var(--gradient);
      color: var(--neon-pink);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      filter: blur(15px);
      opacity: 0.6;
      pointer-events: none;
      white-space: nowrap;
    }
  }
}

h2 {
  font-size: 50px;

  @media all and (max-width: 768px) {
    font-size: 26px;
  }
}

h3 {
  font-size: 40px;

  @media all and (max-width: 768px) {
    font-size: 24px;
  }
}
