body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--light);
  background: var(--dark-blue-01);

  &.modal-open {
    overflow: hidden;
  }
}

input,
textarea {
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

img {
  height: auto;
}

.content {
  max-width: 1200px;
  margin: 0 auto;

  @media all and (max-width: 1200px) {
    margin: 0 20px;
    max-width: unset;
    width: calc(100% - 40px);
  }
}

.main {
  overflow: hidden;
}

.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
