header {
  padding: 5px 0;

  .content{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header-download-links{
    display: flex;
    align-items: center;

    span{
      margin-right: 25px;
    }

    a.secondary {
      margin-right: 15px;

      .separator{
        margin: 0px 15px;
      }
    }
    
    a.secondary:last-child{
      margin: 0;
    }
  }

  // tablet
  @media all and (max-width: 1023px) {
    .header-download-links {
      display: none;
    }
  }

  @media all and (max-width: 768px) {
    .content {
      display: flex;
      justify-content: center;

      svg {
        display: flex;
        margin-left: -15px;
      }
    }
  }
}
