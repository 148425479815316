.highlights {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  svg {
    margin-top: 180px;

    @media all and (max-width: 768px) {
      margin-top: 80px;
    }
  }
  h2 {
    max-width: 645px;
    margin-bottom: 10px;
    margin-top: 40px;

    strong:first-of-type:after {
      content: "Games. Events. Frens.";
    }

    @media all and (max-width: 768px) {
      line-height: 42px;
      margin-left: 15px;
      margin-right: 15px;
      margin-bottom: 20px;
    }
  }

  p {
    margin: 0;
    color: var(--grey-03);
    font-size: 18px;
    font-weight: 500;

    @media all and (max-width: 768px) {
      font-size: 16px;
      margin: 0 15px 0;
    }
  }
}

.top-wrapper {
  width: 100%;
  background-image: url("../../../assets/img/mountain-stars.png");
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: 100%;
  padding-bottom: 300px;

  @media all and (max-width: 768px) {
    padding-bottom: 190px;
    background-size: 160%;
  }

  @media all and (max-width: 440px) {
    padding-bottom: 190px;
    background-size: 220%;
  }

  @media all and (max-width: 360px) {
    padding-bottom: 140px;
    background-size: 240%;
  }
}

.sections {
  display: flex;
  width: 100%;
  background-image: url("../../../assets/img/pattern.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100%;

  .highlight-01 {
    display: flex;
    align-items: center;
    margin: 140px auto 280px;

    @media all and (max-width: 1200px) {
      margin-bottom: 160px;
    }

    @media all and (max-width: 800px) {
      margin-bottom: 140px;
    }

    .highlight-title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-right: 90px;
      z-index: 3;

      h3 {
        text-align: left;
        margin: 20px 0 15px;

        @media all and (max-width: 1024px) {
          text-align: center;
        }

        strong:after {
          content: "Games";
        }
      }

      > p {
        color: var(--grey-03);
        margin: 0;
        font-weight: 500;
      }
    }

    .highlight-images {
      position: relative;
      .background {
        margin: -60px;
      }

      .highlight-img {
        position: absolute;
        top: 100px;

        &.highlight-01-image-01 {
          left: -40px;
          top: 92px;
        }

        &.highlight-01-image-02 {
          left: 245px;
        }

        &.highlight-01-image-03 {
          left: 530px;
        }
      }

      @media all and (max-width: 830px) {
        .background {
          width: 100%;
          height: auto;
          margin: 0;
        }

        .highlight-img {
          width: 35%;
          height: auto;
          top: 26%;

          &.highlight-01-image-01 {
            left: 2%;
            top: 25%;
          }

          &.highlight-01-image-02 {
            left: 34.5%;
          }

          &.highlight-01-image-03 {
            left: auto;
            right: -2%;
          }
        }
      }
    }

    @media all and (max-width: 1200px) {
      flex-direction: column;

      .highlight-title {
        margin-right: 0;
        text-align: center;
        align-items: center;

        h3 {
          margin-top: 40px;
        }
      }
    }
  }

  .highlight-02 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto 320px;

    @media all and (max-width: 1200px) {
      margin-bottom: 200px;
    }

    @media all and (max-width: 800px) {
      margin-bottom: 140px;
    }

    .highlight-title {
      display: flex;
      flex-direction: column;
      align-items: center;

      h3 {
        text-align: center;
        margin: 20px 0 15px;

        strong:after {
          content: "Gain XP";
        }
      }

      > p {
        color: var(--grey-03);
        margin: 0;
        font-weight: 500;
        max-width: 492px;
        text-align: center;

        @media all and (max-width: 540px) {
          width: 100%;
          max-width: unset;
        }
      }
    }

    .highlight-wrapper {
      display: flex;
      margin-top: 60px;
      align-items: center;

      @media all and (max-width: 630px) {
        margin-top: 10px;
      }

      ul {
        position: relative;
        z-index: 3;

        li {
          font-weight: 700;
          font-size: 18px;
          margin-bottom: 40px;
          display: flex;

          @media all and (max-width: 640px) {
            font-size: 16px;
            margin-bottom: 30px;
          }

          > span {
            margin-right: 15px;
          }

          > div {
            margin-left: 15px;

            > span {
              font-size: 14px;
            }
          }
        }
      }

      .highlight-image {
        position: relative;
        .background {
          margin: -60px;
        }

        .highlight-02-image {
          position: absolute;
          top: 30px;
          left: 0px;
          width: 100%;
        }

        @media all and (max-width: 830px) {
          .background {
            width: 100%;
            height: auto;
            margin: 0;
          }

          .highlight-02-image {
            width: 100%;
            height: auto;
            top: 7%;
          }
        }
      }

      @media all and (max-width: 1200px) {
        flex-direction: column-reverse;
        align-items: center;

        .highlight-image {
          margin-bottom: 80px;
        }
      }

      @media all and (max-width: 630px) {
        .highlight-image {
          margin-bottom: 40px;
        }
      }
    }

    @media all and (max-width: 1100px) {
      flex-direction: column;

      .highlight-title {
        margin-right: 0;
        text-align: center;
        align-items: center;

        h3 {
          margin-top: 40px;
        }
      }
    }
  }

  .highlight-03 {
    display: flex;
    align-items: center;
    margin: 0 auto 185px;

    @media all and (max-width: 1200px) {
      margin-bottom: 125px;
    }

    @media all and (max-width: 800px) {
      margin-bottom: 85px;
    }

    .highlight-title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 90px;

      h3 {
        text-align: left;
        margin: 20px 0 15px;

        strong:after {
          content: "Event";
        }
      }

      > p {
        color: var(--grey-03);
        margin: 0;
        font-weight: 500;
        max-width: 492px;

        @media all and (max-width: 540px) {
          text-align: center;
          width: 100%;
          max-width: unset;
        }
      }
    }

    .highlight-events {
      position: relative;
      .background {
        margin: -60px;
      }

      .highlight-img {
        position: absolute;
        top: 90px;

        &.highlight-03-image-01 {
          left: 90px;
        }

        &.highlight-03-image-02 {
          left: 245px;
          top: 13px;
        }

        &.highlight-03-image-03 {
          left: 415px;
        }
      }

      @media all and (max-width: 830px) {
        .background {
          width: 100%;
          height: auto;
          margin: 0;
        }

        .highlight-img {
          width: 30%;
          height: auto;
          top: 22%;

          &.highlight-03-image-01 {
            left: 17%;
          }

          &.highlight-03-image-02 {
            left: 37%;
            top: 9%;
          }

          &.highlight-03-image-03 {
            left: 53%;
          }
        }
      }
    }

    @media all and (max-width: 1200px) {
      flex-direction: column-reverse;

      .highlight-title {
        margin-left: 0;
        text-align: center;
        align-items: center;

        > p {
          margin-bottom: 40px;
        }
      }
    }
  }
}
