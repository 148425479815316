.gradient {
  background: var(--gradient);
  border: none;
  outline: transparent;
  padding: 9px 13px;
  color: var(--light);
  font-size: 12px;
  font-weight: 700;
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease-out;
  margin: 0;

  &.large {
    font-size: 14px;
    padding: 15px 63px;
    border-radius: 10px;
    text-transform: uppercase;
  }

  span {
    position: relative;
    z-index: 2;
  }

  &:hover {
    background: var(--gradient-hover);
  }

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: var(--gradient);
    filter: blur(8px);
    z-index: 1;
    opacity: 0.7;
  }

  &.copied {
    background: var(--grey-01);

    &:before {
      display: none;
    }
  }
}

.secondary {
  padding: 18px;
  border-radius: 10px;
  background: var(--dark-blue-02);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-out;

  &:hover {
    background: var(--grey-01);
  }
}

.primary {
  width: 270px;
  height: 52px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: var(--light);
  border-radius: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(--dark-blue-01);
  transition: all 0.2s ease-out;

  &:hover {
    background: linear-gradient(0deg, rgba(22, 22, 34, 0.1), rgba(22, 22, 34, 0.1)), #f8f8ff;
  }

  &:focus {
    background: linear-gradient(0deg, rgba(22, 22, 34, 0.2), rgba(22, 22, 34, 0.2)), #f8f8ff;
  }

  svg {
    margin-right: 10px;
  }
}