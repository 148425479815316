.select-box {
  position: relative;
  display: block;
  width: 280px;
  font-size: 14px;
  color: #fff;
  margin-bottom: 60px;
}

.select-box__current {
  position: relative;
  cursor: pointer;
}

.select-box__current:focus + .select-box__list {
  opacity: 1;
  animation-name: none;
}
.select-box__current:focus + .select-box__list .select-box__option {
  cursor: pointer;
}
.select-box__current:focus .select-box__icon {
  transform: translateY(-50%) rotate(180deg);
}
.select-box__icon {
  width: 16px;
  height: 20px;
  background: url("../../../assets/svg/arrow.svg") no-repeat center center;
  background-size: 16px 20px;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  transition: 0.2s ease;
}
.select-box__value {
  display: flex;
}
.select-box__input {
  display: none;
}
.select-box__input:checked + .select-box__input-text {
  display: block;
}
.select-box__input-text {
  display: none;
  width: 100%;
  margin: 0;
  padding: 15px 20px;
  background: var(--grey-01);
  border-radius: 10px;
}
.select-box__list {
  position: absolute;
  top: 60px;
  width: 100%;
  padding: 10px 0;
  list-style: none;
  opacity: 0;
  border: 1px solid var(--grey-02);
  border-radius: 10px;
  background: var(--grey-01);
}
.select-box__option {
  display: block;
  padding: 15px 20px;
}
.select-box__option:hover, .select-box__option:focus {
  color: #546c84;
  background-color: #fbfbfb;
}
